import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ENV } from 'env/env';
import { isValidDate } from 'helpers/utils';

export const stockReportsApi = createApi({
  reducerPath: 'stockReportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().persistedReducer.user.accessToken;
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getStockReportList: builder.query({
      query: params => {
        return {
          url: 'stockReport',
          method: 'GET',
          params: {
            ...(params.value &&
              params.value !== '' &&
              !isValidDate(params.value) && {
                ['filters[0][field]']: 'partner.name',
                ['filters[0][operator]']: 'icontains',
                ['filters[0][value]']: params.value
              }),
            ...(params.value &&
              params.value !== '' &&
              isValidDate(params.value) && {
                ['filters[0][field]']: 'createdAt',
                ['filters[0][operator]']: '>=',
                ['filters[0][value]']: params.value
              }),
            limit: params.limit || 20,
            offset: params.from,
            dir: 'DESC'
          }
        };
      },

      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getBillingGroupPartnersStockReportsList: builder.query({
      query: params => {
        return {
          url: 'stockReport/billing-group',
          method: 'GET',
          params: {
            ...(params.value &&
              params.value !== '' &&
              !isValidDate(params.value) && {
                ['filters[0][field]']: 'partner.name',
                ['filters[0][operator]']: 'icontains',
                ['filters[0][value]']: params.value
              }),
            ...(params.value &&
              params.value !== '' &&
              isValidDate(params.value) && {
                ['filters[0][field]']: 'createdAt',
                ['filters[0][operator]']: '>=',
                ['filters[0][value]']: params.value
              }),
            limit: params.limit || 20,
            offset: params.from,
            dir: 'DESC'
          }
        };
      },

      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),

    getStockReport: builder.query({
      query: id => {
        return {
          url: `stockReport/${id}`,
          method: 'GET'
        };
      },
      transformResponse: response => response.data
    }),
    getStockReportExcel: builder.query({
      query: id => {
        return {
          url: `stockReport/excel/${id}`,
          method: 'GET'
        };
      },
      transformResponse: response => {
        return response;
      }
    })
  })
});

export const {
  useGetStockReportListQuery,
  useGetStockReportQuery,
  useGetStockReportExcelQuery,
  useGetBillingGroupPartnersStockReportsListQuery
} = stockReportsApi;
