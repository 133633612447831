import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TransferTableHeader from 'components/container-transfer/TransferTableHeader';
import {
  containerColumnsBulkTransfer,
  userColumnsBulkTransfer
} from 'components/utilities/columnData';
import useError from 'hooks/useError';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { useGetContainerListQuery } from 'redux/endpoints/containersApi';
import { useGetRestaurantsV2Query } from 'redux/endpoints/restaurantApi';
import { useGetUsersListQueryQuery } from 'redux/endpoints/userApi';

const BulkTransfer = () => {
  const id = window.location.pathname.replace('/bulk-transfer/', '');
  const { checkError } = useError();
  const [userSelected, setUserSelected] = useState();
  const [usersOffset, setUsersOffset] = useState(0);
  const [showSpan, setShowSpan] = useState(false);
  const [type, setType] = useState(null);
  const [searchUser, setSearchUser] = useState('');
  const [containersToShow, setContainersToShow] = useState([]);
  const { data: filteredRestaurant, error: filteredRestaurantError } =
    useGetRestaurantsV2Query({
      value: id
    });
  const { data: containersData, error: containersError } =
    useGetContainerListQuery(
      {
        from: 0,
        field: 'currentOwnerId',
        operator: '=',
        value: id,
        limit: 1000000000
      },
      { refetchOnMountOrArgChange: true }
    );

  const { data, error } = useGetUsersListQueryQuery(
    {
      from: usersOffset,
      limit: 20,
      type: type,
      value: searchUser
    },
    { refetchOnMountOrArgChange: true }
  );

  if (error) {
    checkError(error);
  }

  useEffect(() => {
    if (filteredRestaurantError) {
      checkError(filteredRestaurantError);
    }
  }, [filteredRestaurantError]);

  useEffect(() => {
    if (containersError) {
      checkError(containersError);
    }
  }, [filteredRestaurantError]);
  useEffect(() => {
    if (containersData) {
      setContainersToShow(containersData.data);
    }
  }, [filteredRestaurantError]);

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Transferir envases</Card.Title>
      </Card.Header>
      <Card.Body className="p-3 bg-light">
        <Form>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Dueño/a actual</Form.Label>
            <Form.Control
              readOnly
              onChange={() => {}}
              type="text"
              name="name"
              value={filteredRestaurant?.data[0]?.name}
            />
          </Form.Group>
        </Form>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Destino</Form.Label>
            <Form.Control
              readOnly
              onChange={e => {
                setUserSelected(e.target.value);
              }}
              value={userSelected?.name}
              type="text"
              name="name"
              placeholder="¿Adónde quieres transferir el envase?"
            />
            {showSpan &&
              filteredRestaurant?.data[0].type[0] !== 'restaurant' && (
                <Form.Text className="text-danger">
                  Debes seleccionar un restaurante
                </Form.Text>
              )}
            {showSpan &&
              filteredRestaurant?.data[0].type[0] === 'restaurant' && (
                <Form.Text className="text-danger">
                  Debes seleccionar un usuario o admin
                </Form.Text>
              )}
          </Form.Group>
        </Form>
        {userSelected ? (
          containersData?.data && (
            <>
              <Card.Title>¿Qué envases quieres transferir?</Card.Title>
              <Card>
                <AdvanceTableWrapper
                  selection
                  columns={containerColumnsBulkTransfer}
                  data={containersToShow || []}
                  pagination
                  perPage={30}
                >
                  <Card.Header>
                    <Col
                      lg={12}
                      className="d-flex flex-direction-row align-center justify-content-between"
                    >
                      <Col className="mb-2">
                        <Card.Text className="m-0">
                          Envases ({containersToShow.length})
                        </Card.Text>
                        <Card.Link
                          style={{ cursor: 'pointer' }}
                          onClick={() => setUserSelected()}
                        >
                          <Card.Text href="#">Volver a usuarios</Card.Text>
                        </Card.Link>
                      </Col>
                      <TransferTableHeader
                        selectedUser={userSelected}
                        user={filteredRestaurant?.data[0]}
                        table
                        containers={containersData?.data}
                        setContainersToShow={setContainersToShow}
                      />
                    </Col>
                    <Col className="d-flex flex-direction-row justify-content-between">
                      <Col className="d-flex flex-direction-row">
                        <AdvanceTableFooter
                          rowCount={containersToShow.length}
                          table
                          rowInfo
                          navButtons
                        />
                      </Col>
                    </Col>
                  </Card.Header>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </AdvanceTableWrapper>
              </Card>
            </>
          )
        ) : (
          <>
            <Card.Title>
              Usuarios ({data?.data ? data?.pagination.total : 0})
            </Card.Title>
            <Card>
              <AdvanceTableWrapper
                columns={userColumnsBulkTransfer(
                  filteredRestaurant,
                  setShowSpan,
                  setUserSelected,
                  true
                )}
                data={data?.data || []}
                pagination
                perPage={30}
              >
                <Card.Header className="d-flex justify-content-between">
                  <Col>
                    <Button
                      disabled={data?.pagination.offset === 0}
                      variant="primary"
                      onClick={() => setUsersOffset(usersOffset - 20)}
                    >
                      Anteriores
                    </Button>
                    <Button
                      disabled={
                        data?.pagination.offset + 20 > data?.pagination.total
                      }
                      variant="primary"
                      onClick={() => setUsersOffset(usersOffset + 20)}
                    >
                      Siguientes
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-between">
                    <Col lg={5} style={{ position: 'relative' }}>
                      <Form>
                        <Form.Control
                          type="search"
                          placeholder="Search..."
                          size="sm"
                          aria-label="Search"
                          className="rounded search-input ps-4"
                          onChange={({ target }) => {
                            setSearchUser(target.value);
                          }}
                        />
                        <FontAwesomeIcon
                          style={{ top: '15px', left: '-5px' }}
                          icon="search"
                          className="fs--1 text-400 text-400 position-absolute translate-middle-y ms-3"
                        />
                      </Form>
                    </Col>
                    <Col>
                      <Form.Select
                        onChange={e => setType(e.target.value)}
                        style={{ width: '180px' }}
                        size="sm"
                      >
                        <option value="">Todos</option>
                        <option value="customer">Customer</option>
                        <option value="partner">Partner</option>
                        <option value="admin">Admin</option>
                      </Form.Select>
                    </Col>
                  </Col>
                </Card.Header>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
            </Card>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default BulkTransfer;
