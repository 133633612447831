import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { exactTimeConverter } from 'components/utilities/timeConverter';
import { userBadgetType } from 'components/utilities/badgeSelector';
import { useGetUserQueryQuery } from 'redux/endpoints/userApi';
import { QRCodeSVG } from 'qrcode.react';

const ContainerDetailsInfo = ({ data }) => {
  const { data: currentOwnerData } = useGetUserQueryQuery(data?.currentOwnerId);
  console.log(data);
  return (
    <Card className="mb-2 p-3">
      <Row>
        <Col
          className="d-flex align-items-center justify-content-between"
          lg={12}
        >
          <Card.Header className="fw-bold w-100 fs-2">Detalles</Card.Header>
          <Card.Body className="p-0"></Card.Body>
        </Col>
        <Col
          lg={12}
          className="mt-3 d-flex border-top align-items-center justify-content-between p-3"
        >
          <Row className="p-3">
            <Col lg={12} className="d-flex justify-content-between flex-row">
              <Col lg={7}>
                <Card.Title className="mb-3">Información del envase</Card.Title>
              </Col>
              <Col>
                <Card.Title className="mb-3 ms-3">
                  Información del dueño actual
                </Card.Title>
              </Col>
            </Col>
            <Col lg={7}>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">ID:</Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Text className="m-1">{data?.id}</Card.Text>
                </Col>
                <Col>
                  <QRCodeSVG
                    size={60}
                    value={data?.url ? data?.url : data?.id}
                  />
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row">
                <Col lg={4}>
                  <Card.Text className="m-1">URL ID:</Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Text className="m-1">{data?.url}</Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Creado:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {exactTimeConverter(data?.createdAt)}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Tipo de envase:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.productType.name}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Último movimiento:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {exactTimeConverter(data?.updatedAt)}
                  </Card.Text>
                </Col>
              </Col>
            </Col>
            <Col lg={5}>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Arrendatario:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.currentOwner.name}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Categoría:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {userBadgetType(data?.currentOwner.type)}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Email:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {currentOwnerData?.email}
                  </Card.Text>
                </Col>
              </Col>
              {/* {journey?.data[0] && (
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={4}>
                    <Card.Text className="m-1">Arrendador:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">
                      {journey?.data[0].giver.name}
                    </Card.Text>
                  </Col>
                </Col>
              )} */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

ContainerDetailsInfo.propTypes = {
  data: PropTypes.object,
  journey: PropTypes.object
};

export default ContainerDetailsInfo;
