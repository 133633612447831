import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Flex from 'components/common/Flex';

import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { t } from 'i18next';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

const columns = [
  {
    accessor: 'name',
    Header: 'Centro',
    cellProps: {
      style: { maxWidth: '23rem' }
    },
    Cell: rowData => (
      <Flex alignItems="center" className="gap-3 position-relative">
        {rowData.row.original.name}
      </Flex>
    )
  },
  {
    accessor: 'date',
    Header: 'Fecha de inventario',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData =>
      `${new Date(rowData.row.original.createdAt).toLocaleString(
        {
          language: 'es'
        },
        { timeStyle: 'short', dateStyle: 'medium' }
      )}`
  },
  {
    accessor: 'totalContainers',
    Header: 'Total Almacén',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData =>
      rowData.row.original.scannedContainers +
      rowData.row.original.missingContainers
  },
  {
    accessor: 'scanned',
    Header: 'Envases escaneados',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData => rowData.row.original.scannedContainers
  },
  {
    accessor: 'missing',
    Header: 'Envases no escaneados',
    headerProps: {
      className: 'fw-medium'
    },

    Cell: rowData => rowData.row.original.missingContainers
  }
];

const StockHistoryTable = ({ tableData }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={tableData.data}
      sortable
      pagination
      perPage={5}
      searchable
    >
      <Card className="h-100">
        <Card.Header className="d-flex flex-between-center">
          <h6 className="mb-0">{t('allPartnersStocks')}</h6>
          <div>
            <AdvanceTableSearchBox placeholder={t('search')} table />
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-900 fw-medium font-sans-serif white-space-nowrap"
            rowClassName="btn-reveal-trigger align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden fw-semi-bold'
            }}
          />
          <div className="p-3">
            <AdvanceTableFooter
              rowCount={tableData.data.length}
              table
              navButtons
              rowInfo
              rowsPerPageSelection
            />
          </div>
        </Card.Body>
      </Card>
    </AdvanceTableWrapper>
  );
};

StockHistoryTable.propTypes = {
  tableData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    )
  })
};

export default StockHistoryTable;
