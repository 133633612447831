import Flex from 'components/common/Flex';
import badgeSelector from 'components/utilities/badgeSelector';
import { svgSelectorDetailed } from 'components/utilities/svgSelector';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const detailsStockColumn = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/container-details/${id}`}>
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'productType',
    Header: 'Tipo de Producto',
    Cell: rowData => {
      const { productType } = rowData.row.original;
      return (
        <Flex>
          <Card.Text>
            {svgSelectorDetailed(productType.code)} {productType.name}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    Cell: rowData => {
      const { status } = rowData.row.original;
      return <Flex>{badgeSelector(status)}</Flex>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    }
  }
];
