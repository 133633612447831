import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownFilter from 'components/common/DropdownFilter';
import useError from 'hooks/useError';
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetProductTypesQuery } from 'redux/endpoints/productTypesApi';
import { useGetStockReportExcelQuery } from 'redux/endpoints/stockReportsApi';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { getDateBeforeDays } from 'helpers/utils';

const PartnersTableHeader = () => {
  const path = window.location.pathname;
  const id = window.location.pathname.replace('/stock-report/', '');

  const navigate = useNavigate();
  const { checkError } = useError();
  const { data, error } = useGetProductTypesQuery();
  const [stockReportId, setStockReportId] = useState(null);
  const {
    data: excelData,
    isError,
    isLoading
  } = useGetStockReportExcelQuery(stockReportId, { skip: !stockReportId });

  const handleSubmit = () => {
    console.log(stockReportId);
    setStockReportId(id);
    if (excelData) {
      downloadExcel();
    }
  };

  useEffect(() => {
    fetchData();
  }, [excelData]);
  async function fetchData() {
    if (excelData) {
      downloadExcel();
    }
  }
  async function downloadExcel() {
    try {
      console.log(excelData);
      const binaryData = new Uint8Array(excelData.data.data);
      const blob = new Blob([binaryData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, 'stockReport.xlsx');
      setStockReportId(null);
    } catch (error) {
      toast.error('Error al exportar el archivo', {
        position: 'top-center'
      });
    }
  }
  if (isLoading) {
    return console.log('Loading...');
  }
  if (isError) {
    return console.log('An error has occurred');
  }

  if (error) {
    checkError(error);
  }

  const getTypeId = name => {
    const productType = data?.find(productType => productType.name === name);
    return productType?.id;
  };

  const timeFilters = [
    { placeholder: 'Últimos 7 días', days: 7 },
    { placeholder: 'Últimos 30 días', days: 30 },
    { placeholder: 'Últimos 90 días', days: 90 },
    { placeholder: 'Ultimo año', days: 365 }
  ];

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          {path.includes('/containers-list') && 'Listado de envases'}
          {path.includes('/partners-list') && 'Listado de centros'}
          {path.includes('/users-list') && 'Listado de usuarios'}
          {path === '/subscriptions' && 'Listado de suscripciones'}
          {path.includes('/billing-list') && 'Grupos de facturación'}
          {path.includes('/stock-list') && 'Listado de inventarios'}
        </h5>
      </Col>
      {path.includes('/partners-list') && (
        <Col
          lg={8}
          className="d-flex justify-content-end align-items-center mt-0 text-end ps-0"
        >
          <Form className="d-flex align-items-center">
            <Form.Control
              type="search"
              placeholder="Buscar..."
              size="sm"
              aria-label="Search"
              className="rounded search-input ps-4"
              onChange={({ target }) => {
                navigate(`/partners-list/1?query=${target.value}`);
              }}
            />
            <FontAwesomeIcon
              icon="search"
              style={{ top: '7px', position: 'relative', left: '-204px' }}
              className="fs--1 text-400 text-400v translate-middle-y ms-4"
            />
          </Form>
          <Button
            variant="primary"
            size="sm"
            style={{ height: '35px', width: '150px' }}
            className="text-center me-3"
            onClick={() => navigate('/partner-creation')}
          >
            Crear Centro
          </Button>
        </Col>
      )}
      {path.includes('/containers-list') && (
        <Col
          lg={8}
          className="d-flex justify-content-end align-items-center mt-0 text-end ps-0"
        >
          <Form className="d-flex align-items-center">
            <Form.Control
              type="search"
              placeholder="Buscar..."
              size="sm"
              aria-label="Search"
              className="rounded search-input ps-4"
              onChange={({ target }) => {
                navigate(`/containers-list/1?query=${target.value}`);
              }}
            />
            <FontAwesomeIcon
              icon="search"
              style={{ top: '7px', position: 'relative', left: '-204px' }}
              className="fs--1 text-400 text-400 translate-middle-y ms-4"
            />
          </Form>
          {data && (
            <DropdownFilter
              icon="fas fa-filter"
              currentFilter="Filtros"
              handleFilter={e =>
                navigate(`/containers-list/1?type=${getTypeId(e)}`)
              }
              filters={data.map(product => product.name)}
            />
          )}
          <Button
            variant="primary"
            size="sm"
            style={{ height: '35px', width: '150px' }}
            className="text-center me-3"
            onClick={() => navigate('/containers-creation')}
          >
            + Nuevo
          </Button>
        </Col>
      )}

      {path.includes('/users-list') && (
        <Col lg={3} className="position-relative start-0 mt-2">
          <Form className="d-flex align-items-center justify-content-start">
            <Form.Control
              type="search"
              placeholder="Buscar..."
              size="sm"
              aria-label="Search"
              className="rounded search-input ps-4"
              onChange={({ target }) => {
                navigate(`/users-list/1?query=${target.value}`);
              }}
            />
          </Form>
          <FontAwesomeIcon
            icon="search"
            style={{
              position: 'absolute',
              top: '15px',
              right: 'auto',
              paddingLeft: '10px'
            }}
            className="fs--1 text-400 text-400 translate-middle-y"
          />
        </Col>
      )}
      {path === '/subscriptions' && (
        <Col lg={4} className="start-0 mt-2">
          <Form className="d-flex align-items-center">
            <div style={{ width: '100%' }} className="position-relative me-4">
              <Form.Control
                disabled
                type="search"
                placeholder="Buscar..."
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
                onChange={({ target }) => {
                  navigate(`/subscriptions?query=${target.value}`);
                }}
              />
              <FontAwesomeIcon
                icon="search"
                style={{ position: 'absolute', top: '15px', left: '-15px' }}
                className="fs--1 text-400 text-400 translate-middle-y ms-4"
              />
            </div>
          </Form>
        </Col>
      )}
      {path.includes('/billing-list') && (
        <Col className="d-flex justify-content-end align-items-center mt-0 text-end ps-0">
          <Form className="d-flex align-items-center">
            <Form.Control
              type="search"
              placeholder="Buscar..."
              size="sm"
              aria-label="Search"
              className="rounded search-input ps-4"
              onChange={({ target }) => {
                navigate(`/billing-list/1?query=${target.value}`);
              }}
            />
            <FontAwesomeIcon
              icon="search"
              style={{ top: '7px', position: 'relative', left: '-204px' }}
              className="fs--1 text-400 text-400 translate-middle-y ms-4"
            />
          </Form>
          {/* {data && (
            <DropdownFilter
              icon="fas fa-filter"
              currentFilter="Filtros"
              handleFilter={e =>
                navigate(`/containers-list/1?type=${getTypeId(e)}`)
              }
              filters={data.map(product => product.name)}
            />
          )} */}
          <Button
            variant="primary"
            size="sm"
            style={{ height: '30px', width: '100px' }}
            className="text-center me-3"
            onClick={() => navigate('/billing-group/new')}
          >
            + Nuevo
          </Button>
        </Col>
      )}
      {path.includes('/stock-list') && (
        <Col
          lg={8}
          className="d-flex justify-content-end align-items-center mt-0 text-end ps-0"
        >
          <Form className="d-flex align-items-center">
            <Form.Control
              type="search"
              placeholder="Buscar..."
              size="sm"
              aria-label="Search"
              className="rounded search-input ps-4"
              onChange={({ target }) => {
                navigate(`/stock-list/1?query=${target.value}`);
              }}
            />
            <FontAwesomeIcon
              icon="search"
              style={{ top: '7px', position: 'relative', left: '-204px' }}
              className="fs--1 text-400 text-400 translate-middle-y ms-4"
            />
          </Form>
          {data && (
            <DropdownFilter
              icon="fas fa-filter"
              currentFilter="Filtros"
              handleFilter={e => {
                console.log(
                  timeFilters.find(filter => filter.placeholder === e).days
                );
                navigate(
                  `/stock-list/1?createdAt=${getDateBeforeDays(
                    timeFilters.find(filter => filter.placeholder === e).days
                  )}`
                );
              }}
              filters={timeFilters.map(filter => filter.placeholder)}
            />
          )}
        </Col>
      )}

      {path.includes('/stock-report') && (
        <Col
          lg={8}
          className="d-flex justify-content-end align-items-center mt-0 text-end ps-0"
        >
          <Button
            variant="primary"
            size="sm"
            style={{ height: '30px', width: '150px' }}
            className="me-0"
            onClick={handleSubmit}
          >
            Exportar
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default PartnersTableHeader;
