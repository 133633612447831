import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Flex from 'components/common/Flex';
import {
  aggregateDataByPartnerId,
  getMonthNameFromFilter
} from 'helpers/utils';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { t } from 'i18next';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

const columns = [
  {
    accessor: 'partnerName',
    Header: 'Centro',
    cellProps: {
      style: { maxWidth: '23rem' }
    },
    Cell: rowData => (
      <Flex alignItems="center" className="gap-3 position-relative">
        {rowData.row.original.partnerName}
      </Flex>
    )
  },
  {
    accessor: 'users',
    Header: 'Usuarios',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData =>
      `${new Number(rowData.row.original.userCount).toLocaleString()}`
  },
  {
    accessor: 'containers',
    Header: 'Envases evitados',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData =>
      `${new Number(rowData.row.original.lendCount).toLocaleString()}`
  },
  {
    accessor: 'waste',
    Header: 'Kg de Residuos evitados',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData =>
      new Number(
        (
          (rowData.row.original.bowlsLentCount * 20.8 +
            rowData.row.original.cupLentCount * 18) /
          1000
        ).toFixed(2)
      ).toLocaleString()
  },
  {
    accessor: 'co2',
    Header: 'Kg de CO2 evitados',
    headerProps: {
      className: 'fw-medium'
    },

    Cell: rowData =>
      new Number(
        (
          (rowData.row.original.bowlsLentCount * 20 +
            rowData.row.original.cupLentCount * 17) /
          1000
        ).toFixed(2)
      ).toLocaleString()
  }
  /* {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: () => {
      return (
        <CardDropdown iconClassName="fs--1" drop="start">
          <div className="py-2">
            <Dropdown.Item href="#!">View</Dropdown.Item>
            <Dropdown.Item href="#!">Edit</Dropdown.Item>
            <Dropdown.Item href="#!">Refund</Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item href="#!" className="text-warning">
              Archive
            </Dropdown.Item>
            <Dropdown.Item href="#!" className="text-danger">
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  } */
];

const GroupPartnerTable = ({ tableData, currentFilter }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={aggregateDataByPartnerId(tableData.items)}
      sortable
      pagination
      perPage={5}
      searchable
    >
      <Card className="h-100">
        <Card.Header className="d-flex flex-between-center">
          <h6 className="mb-0">{`${t('infoByPartner')} - ${
            getMonthNameFromFilter(currentFilter)[0].toUpperCase() +
            getMonthNameFromFilter(currentFilter).slice(1)
          }`}</h6>
          <div>
            <AdvanceTableSearchBox placeholder={t('search')} table />
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-900 fw-medium font-sans-serif white-space-nowrap"
            rowClassName="btn-reveal-trigger align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden fw-semi-bold'
            }}
          />
          <div className="p-3">
            <AdvanceTableFooter
              rowCount={aggregateDataByPartnerId(tableData.items).length}
              table
              navButtons
              rowInfo
              rowsPerPageSelection
            />
          </div>
        </Card.Body>
      </Card>
    </AdvanceTableWrapper>
  );
};

GroupPartnerTable.propTypes = {
  currentFilter: PropTypes.string.isRequired,
  tableData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        billingGroupId: PropTypes.string.isRequired,
        bowlLentPercentDifferenceWithLastMonth: PropTypes.string.isRequired,
        bowlsLentCount: PropTypes.string.isRequired,
        cupLentCount: PropTypes.string.isRequired,
        cupLentPercentDifferenceWithLastMonth: PropTypes.string.isRequired,
        lendCount: PropTypes.string.isRequired,
        lentAt: PropTypes.string.isRequired,
        partnerId: PropTypes.string.isRequired,
        partnerName: PropTypes.string.isRequired,
        partners: PropTypes.number.isRequired,
        totalLendsPercentDifferenceWithLastMonth: PropTypes.string.isRequired,
        userCount: PropTypes.string.isRequired
      })
    ),
    count: PropTypes.number.isRequired
  }).isRequired,
  perPage: PropTypes.number
};

export default GroupPartnerTable;
